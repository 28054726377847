// Refactoring №2
export enum AuthDialogType {
  Login = 'Login',
  LoginWithWallet = 'Login with wallet',
  Register = 'Register',
  SuccessfulRegister = 'Successfully registration',
  ResetPassword = 'Reset password',
}

export enum UserRole {
  User = 'USER',
  Creator = 'CREATOR',
  Admin = 'ADMIN',
}

export type InitData = {
  nickname?: string;
  google2fa?: boolean;
  gameAccount?: string;
};
