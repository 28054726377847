// Refactoring №1
// Refactoring №2
import { Context, createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import {
  MOBILE_SIZE_LOWER_LIMIT,
  MOBILE_SIZE_UPPER_LIMIT,
  TABLET_SIZE_LOWER_LIMIT,
  DESKTOP_SIZE_LOWER_LIMIT,
  DESKTOP_SIZE_UPPER_LIMIT,
} from 'constants/sizes';

interface ScreenSizeContextValues {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface ScreenSizeProviderProps {
  children?: ReactNode;
  // any props that come into the component
}

// eslint-disable-next-line max-len
const ScreenSizeContext: Context<null | ScreenSizeContextValues> = createContext<null | ScreenSizeContextValues>(null);

export const ScreenSizeProvider: FC<ScreenSizeProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= MOBILE_SIZE_UPPER_LIMIT);
  const [isTablet, setIsTablet] = useState<boolean>(window.innerWidth <= TABLET_SIZE_LOWER_LIMIT);
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth <= DESKTOP_SIZE_UPPER_LIMIT);

  // eslint-disable-next-line function-name/starts-with-verb
  const resizeHandler = (): void => {
    setIsMobile(window.innerWidth >= MOBILE_SIZE_LOWER_LIMIT && window.innerWidth <= MOBILE_SIZE_UPPER_LIMIT);
    setIsTablet(window.innerWidth >= MOBILE_SIZE_UPPER_LIMIT && window.innerWidth <= TABLET_SIZE_LOWER_LIMIT);
    setIsDesktop(window.innerWidth >= DESKTOP_SIZE_LOWER_LIMIT && window.innerWidth <= DESKTOP_SIZE_UPPER_LIMIT);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  return <ScreenSizeContext.Provider value={{ isMobile, isTablet, isDesktop }}>{children}</ScreenSizeContext.Provider>;
};

export const useScreenSize = () => {
  const context = useContext(ScreenSizeContext);
  if (!context) {
    throw new Error('Missing referrer context');
  }

  return {
    isMobile: context.isMobile,
    isTablet: context.isTablet,
    isDesktop: context.isDesktop,
  };
};

export const isMobileDevice = (): boolean => {
  return 'ontouchstart' in window || 'onmsgesturechange' in window;
};
