import { IValidation } from 'interfaces';
import { AnyObjectSchema } from 'yup';

// Refactoring №2
const validateEmail = (email: string) => {
  return /\S+@\S+\.\S+/.test(email);
};

const validateNickname = (nickname: string) => {
  return /[0-9_a-zA-Z]+$/g.test(nickname); // only numbers, lower and upper case letters
};

const validateValueMinLength = (value: string, minLength: number) => {
  return value.length >= minLength;
};

const validatePassword = (password: string, repeatPassword: string) => {
  return password === repeatPassword;
};

const validatePrice = (price: string, minPrice: string) => {
  return +price >= +minPrice;
};

const validateMinPrice = (price: string) => {
  return +price > 0;
};

export const proxyValidation = {
  email: validateEmail,
  nickname: validateNickname,
  minLength: validateValueMinLength,
  passwords: validatePassword,
  minPrice: validatePrice,
  minPrice2: validateMinPrice,
};

export const validate = (data: unknown, schema: AnyObjectSchema): IValidation => {
  const errors = Object.keys(schema.fields).reduce<Record<string, string[]>>((memo, field) => {
    try {
      schema.validateSyncAt(field, data, { abortEarly: false });
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      memo[field] ??= [];
      memo[field].push(error.errors);
    }

    return memo;
  }, {});

  return {
    isValid: !Object.entries(errors).length,
    errors,
  };
};
