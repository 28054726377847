import { makeAutoObservable } from 'mobx';
import { TokenType } from 'types';
import { DESCRIPTION_LIMIT, MAX_PROPERTIES_COUNT, NAME_LIMIT } from 'constants/common';
import { ICategoryData, ICollectionData, INetworkData, INewTokenData, IUser, IMetadataProperty } from 'interfaces';

class CreateNftStore {
  nameLimit = NAME_LIMIT;
  descriptionLimit = DESCRIPTION_LIMIT;

  file: File | null = null;
  dataUrl: string | null = null;
  name = '';
  description = '';
  network?: INetworkData;
  collection?: ICollectionData;
  category?: ICategoryData;
  type = TokenType.ERC721;
  quantity = 1;
  royalty = 0;
  owner!: IUser;

  preview = false;
  metadataProperties: IMetadataProperty[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset() {
    this.file = null;
    this.dataUrl = null;
    this.name = '';
    this.description = '';
    this.network = undefined;
    this.collection = undefined;
    this.category = undefined;
    this.type = TokenType.ERC721;
    this.quantity = 1;
    this.royalty = 0;
    this.metadataProperties = [];
    this.preview = false;
  }

  setPreview(value: boolean) {
    this.preview = value;
  }

  setFile(image: File | null) {
    this.file = image;

    if (!image) {
      this.dataUrl = null;
      return;
    }

    this.dataUrl = URL.createObjectURL(image);
  }

  setName(name: string) {
    if (name.length <= this.nameLimit) {
      this.name = name;
    }
  }

  setDescription(desc: string) {
    if (desc.length <= this.descriptionLimit) {
      this.description = desc;
    }
  }

  setTokenType(tokenType: TokenType) {
    this.type = tokenType;
  }

  setQuantity(amount: string) {
    this.quantity = +amount;
  }

  setNetwork(network: INetworkData | undefined) {
    this.network = network;
  }

  setCollection(collection: ICollectionData | undefined) {
    this.collection = collection;
  }

  setCategory(category: ICategoryData | undefined) {
    this.category = category;
  }

  setRoyalty(royalty: number) {
    this.royalty = royalty;
  }

  setOwner(user: IUser) {
    this.owner = user;
  }

  addMetadataProperty() {
    if (this.metadataProperties.length < MAX_PROPERTIES_COUNT) {
      this.metadataProperties.push({
        name: '',
        value: '',
        isNumber: false,
      });
    }
  }

  deleteMetadataProperty(index: number) {
    this.metadataProperties.splice(index, 1);
  }

  changeMetadataPropertyName(name: string, index: number) {
    this.metadataProperties[index].name = name;
  }

  changeMetadataPropertyValue(value: string | number, index: number) {
    this.metadataProperties[index].value = value as string;
    this.metadataProperties[index].isNumber = !isNaN(value as number);
  }

  get nameLength(): number {
    return this.name ? this.name.length : 0;
  }

  get descriptionLength(): number {
    return this.description ? this.description.length : 0;
  }

  get isMultitoken(): boolean {
    return this.type === TokenType.ERC1155;
  }

  get payload(): INewTokenData {
    return {
      name: this.name,
      description: this.description,
      categoryUuid: this.category?.uuid,
      collectionUuid: this.collection?.uuid,
      networkUuid: this.network?.uuid,
      imageHash: this.dataUrl ?? '',
      network: this.network,
      category: this.category,
      collection: this.collection,
      royalty: this.royalty,
      quantity: this.quantity,
      ownerships: [{ owner: this.owner, ownerUuid: this.owner.uuid }],
      metadata: this.metadataProperties,
    };
  }

  get formData(): FormData {
    const form = new FormData();

    form.append('royalty', this.royalty.toString());
    form.append('name', this.name);
    form.append('type', this.type);
    form.append('description', this.description);

    if (this.file) {
      form.append('file', this.file);
    }

    if (this.network) {
      form.append('networkUuid', this.network.uuid);
    }

    if (this.isMultitoken) {
      form.append('quantity', this.quantity.toString());
    }

    if (this.collection) {
      form.append('collectionUuid', this.collection.uuid);
    }

    if (this.category) {
      form.append('categoryUuid', this.category.uuid);
    }

    if (this.metadataProperties.length) {
      form.append('properties', JSON.stringify(this.metadataProperties));
    }

    return form;
  }
}

export default CreateNftStore;
