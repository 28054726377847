// Refactoring №1
// Refactoring №2
// Refactoring №3
import { t } from 'i18next';
import { flow, makeAutoObservable } from 'mobx';
import { getCategoriesList } from 'api/category';
import { ICategoryData, IListRequest, IMetaResponse } from 'interfaces';
import { INIT_META } from 'constants/common';

class CategoryStore {
  isInitialized = false;

  items: ICategoryData[] = [];

  isOpenCategories = false;

  selectedCategory: string = t('nfts.filters.allCategories');

  meta: IMetaResponse = INIT_META;

  sorting: string[] = ['createdAt:DESC', 'createdAt:ASC'];

  constructor() {
    makeAutoObservable(this, { fetchItems: flow }, { autoBind: true });
  }

  reset() {
    this.isOpenCategories = false;
    this.selectedCategory = t('nfts.filters.allCategories');
  }

  get categoryNames(): string[] {
    return this.items.map((c) => c.name);
  }

  getById(id: string): ICategoryData | undefined {
    const category = this.items.find((c) => c.uuid === id);
    return category;
  }

  getByName(name: string): ICategoryData | undefined {
    const category = this.items.find((c) => c.name === name);
    return category;
  }

  *fetchItems(params: IListRequest = {}) {
    const { data, meta } = yield getCategoriesList({ limit: 200, ...params });
    this.items = data;
    this.meta = meta;
    this.isInitialized = true;
  }

  setIsOpenCategories(isOpen: boolean): void {
    this.isOpenCategories = isOpen;
  }

  setSelectedCategory(category: string): void {
    this.selectedCategory = category;
  }
}

export default CategoryStore;
