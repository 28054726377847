import { observer } from 'mobx-react-lite';
import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useStore } from 'store';

interface IProps {
  children: ReactElement;
}

export const ProtectedRoute: FC<IProps> = observer(({ children }) => {
  const { authStore } = useStore();

  if (!authStore.isAuthorized) {
    return <Navigate to='/nfts' replace />;
  }

  return children;
});
