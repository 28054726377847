// Refactoring №2
export enum ListTypes {
  FilterTree = 'filterTree',
  Checkbox = 'checkbox',
}

export enum Rarity {
  Common = 'Common',
  Rare = 'Rare',
  Epic = 'Epic',
}

export enum DropdownTypes {
  Collections = 'Collections',
  Categories = 'Categories',
  Sorting = 'Sorting',
}

export enum InputTypes {
  Regular = 'Regular',
  Password = 'Password',
  Phone = 'Phone',
  Numbers = 'Numbers',
  Price = 'Price',
}

export enum ButtonTypes {
  Contained = 'contained',
  Outlined = 'outlined',
  Icon = 'icon',
  Successful = 'Successful',
}

export enum GalleryType {
  Gallery = 'Gallery',
  Sale = 'Sale',
}

export enum NotificationTypes {
  success = 'success',
  error = 'error',
  info = 'info',
}

export interface ISortingByRange {
  isOpen: boolean;
  minRange: number;
  maxRange: number;
  minDistance: number;
  step: number;
}

export interface ICheckboxOrFilterTree {
  isOpen: boolean;
  items: string[];
}

export interface ICharacters {
  isOpen: boolean;
  archetype: ICheckboxOrFilterTree;
  level: ISortingByRange;
  skills: ISortingByRange;
  xp: ISortingByRange;
}

export interface ICharacterAbility {
  isOpen: boolean;
  magic: ICheckboxOrFilterTree;
  sword: ICheckboxOrFilterTree;
  crossbow: ICheckboxOrFilterTree;
}

export interface IKingdom {
  isOpen: boolean;
  lands: ICheckboxOrFilterTree;
}

export interface ISearch {
  value: string;
}

export interface ICollectionFilters {
  isOpen: boolean;
  array: string[];
  selected: string;
}

export interface ICategoryFilters {
  isOpen: boolean;
  array: string[];
  selected: string;
}

export interface ISorting {
  isOpen: boolean;
  array: string[];
  selected: string;
}

export interface INft {
  id: string;
  name: string;
  category: string;
  saleToken: string;
  collection: string;
  description: string;
  image: string;
  stock: string;
  owner: string;
  timestamp: number;
  price: string;
  rarity?: Rarity;
  favoriteCount: number;
  mintAmount: number;
  availableAmount: number;
}

export type Time = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};
