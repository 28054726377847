import { getNetworksList } from 'api/network';
import { INIT_META } from 'constants/common';
import { IListRequest, IMetaResponse, INetworkData } from 'interfaces';
import { flow, makeAutoObservable } from 'mobx';

class NetworkStore {
  items: INetworkData[] = [];

  meta: IMetaResponse = INIT_META;

  sorting: string[] = ['createdAt:DESC', 'createdAt:ASC'];

  constructor() {
    makeAutoObservable(this, { fetchItems: flow }, { autoBind: true });
  }

  reset() {
    this.items = [];
    this.meta = INIT_META;
  }

  *fetchItems(params: IListRequest = {}) {
    const { data, meta } = yield getNetworksList({ limit: 200, ...params });
    this.items = data;
    this.meta = meta;
  }

  getHumanName(param: string): string {
    const item = this.items.find((n) => n.uuid === param || n.name === param);
    return item ? item.humanName : '';
  }
}

export default NetworkStore;
