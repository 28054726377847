// Refactoring №2
import API from 'api/API';
import { ICollectionData, IListRequest, IListResponse } from 'interfaces';

export const getCollectionList = async (params?: IListRequest): Promise<IListResponse<ICollectionData>> => {
  const response = await API.get<IListResponse<ICollectionData>>('/collection', { params });
  return response.data;
};

export const getCollectionById = async (id: string): Promise<ICollectionData> => {
  const response = await API.get<ICollectionData>(`/collection/${id}`);
  return response.data;
};

export const createCollection = async (data: FormData): Promise<ICollectionData> => {
  const response = await API.post<FormData, ICollectionData>('/collection', data);
  return response.data;
};
