import { NAME_LIMIT } from 'constants/common';
import { INewCollectionData, IValidationErrors } from 'interfaces';
import { makeAutoObservable } from 'mobx';
import { validate } from 'utils';
import { CreateCollectionSchema } from 'validators';

class CreateCollectionStore {
  nameLimit = NAME_LIMIT;

  file: File | null = null;
  dataUrl: string | null = null;
  name = '';

  errors: IValidationErrors = {};
  isValid = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  reset() {
    this.file = null;
    this.dataUrl = null;
    this.name = '';
    this.errors = {};
    this.isValid = false;
  }

  setFile(image: File | null) {
    this.file = image;

    if (!image) {
      this.dataUrl = null;
      return;
    }

    this.dataUrl = URL.createObjectURL(image);
  }

  setName(name: string) {
    if (name.length <= this.nameLimit) {
      this.name = name;
    }
  }

  removeError(field: string) {
    if (Object.hasOwn(this.errors, field)) {
      delete this.errors[field];
    }
  }

  validate() {
    const { errors, isValid } = validate(this.payload, CreateCollectionSchema);

    this.errors = errors;
    this.isValid = isValid;
  }

  get nameLength(): number {
    return this.name ? this.name.length : 0;
  }

  get payload(): INewCollectionData {
    return {
      file: this.dataUrl || '',
      name: this.name,
    };
  }

  get formData(): FormData {
    const form = new FormData();

    form.append('name', this.name);

    if (this.file) {
      form.append('file', this.file);
    }

    return form;
  }
}

export default CreateCollectionStore;
