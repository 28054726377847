import { AxiosError } from 'axios';
import { IErrorMessageResponse } from 'interfaces';

export const getResponseErrorMessages = (error: unknown): string[] => {
  if (error instanceof AxiosError && error.response) {
    if (Array.isArray(error.response.data.message)) {
      return error.response.data.message.reduce((memo: string[], m: IErrorMessageResponse) => {
        memo.push(...Object.values(m.constraints));

        return memo;
      }, []);
    }

    return [error.response.data.message];
  }

  return [];
};
