import * as Yup from 'yup';

export const AmountCopiesSchema = Yup.object().shape({
  quantity: Yup.number().min(2).required(),
});

export const MetadataPropertiesSchema = Yup.object().shape({
  name: Yup.string().trim().required(),
  value: Yup.string().trim().required(),
  isNumber: Yup.boolean().default(false),
});

export const CreateSingleTokenSchema = Yup.object().shape({
  imageHash: Yup.string().required(),
  name: Yup.string().required(),
  description: Yup.string().required(),
  networkUuid: Yup.string().required(),
  metadata: Yup.array().of(MetadataPropertiesSchema),
});

export const CreateMultiTokenSchema = CreateSingleTokenSchema.concat(AmountCopiesSchema);
