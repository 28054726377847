// Refactoring №2
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { REQUEST_TIMEOUT } from 'constants/common';
import { LS_KEY_ACCESS_TOKEN } from 'constants/localStorage';
import { getFromLS } from 'utils';

interface IConfig extends AxiosRequestConfig {
  isPublic?: boolean;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api-gateway.dev.nft-p2e.4irehub.uk',
  timeout: REQUEST_TIMEOUT,
});

instance.interceptors.request.use(async (config: IConfig) => {
  // TODO: add extra logic, auth token etc...
  if (!config.isPublic) {
    const accessToken = getFromLS(LS_KEY_ACCESS_TOKEN);

    if (accessToken) {
      config.headers ??= {};
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
});

export default {
  get<ResBody>(path: string, config?: IConfig): Promise<AxiosResponse<ResBody>> {
    return instance.get(path, config);
  },

  post<ReqBody, ResBody>(path: string, data?: ReqBody, config?: IConfig): Promise<AxiosResponse<ResBody>> {
    return instance.post(path, data, config);
  },

  put<ReqBody, ResBody>(path: string, data?: ReqBody, config?: IConfig): Promise<AxiosResponse<ResBody>> {
    return instance.put(path, data, config);
  },

  patch<ReqBody, ResBody>(path: string, data?: ReqBody, config?: IConfig): Promise<AxiosResponse<ResBody>> {
    return instance.patch(path, data, config);
  },

  delete<ResBody>(path: string, config?: IConfig): Promise<AxiosResponse<ResBody>> {
    return instance.delete(path, config);
  },
};
