// Refactoring №1
// Refactoring №2
import { InjectedConnector } from '@web3-react/injected-connector';
import { INetworkParams } from 'interfaces';

const abstractConnectorArgs = {
  supportedChainIds: [137, 80001],
};
const injected: InjectedConnector = new InjectedConnector(abstractConnectorArgs);

// https://eips.ethereum.org/EIPS/eip-3085
// Custom networks for Ethereum compatible chains can be added to Metamask
export async function addPolygonNetwork(networkParams: INetworkParams) {
  try {
    const provider = await injected.getProvider();
    // rpc request to switch chain to an ethereum compatible chain
    await provider.request({ method: 'wallet_addEthereumChain', params: [networkParams] });
  } catch (e) {
    console.log(e);
  }
}
