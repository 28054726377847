// Refactoring №1
// Refactoring №2
import { createContext, useContext } from 'react';
import { injectStores } from '@mobx-devtools/tools';
import AccountStore from './stores/AccountStore';
import AuthStore from './stores/AuthStore';
import CategoryStore from './stores/CategoryStore';
import CollectionStore from './stores/CollectionStore';
import CreateCollectionStore from './stores/CreateCollectionStore';
import CreateNftStore from './stores/CreateNftStore';
import CurrencyStore from './stores/CurrencyStore';
import NftStore from './stores/NftStore';
import OfferStore from './stores/OfferStore';
import ProfileStore from './stores/ProfileStore';
import TokenStore from './stores/TokenStore';
import UserOnSaleNftStore from './stores/UserOnSaleNftStore';
import WalletStore from './stores/WalletStore';
import NetworkStore from './stores/NetworkStore';

export const store = {
  accountStore: new AccountStore(),
  authStore: new AuthStore(),
  categoryStore: new CategoryStore(),
  collectionStore: new CollectionStore(),
  createCollectionStore: new CreateCollectionStore(),
  createNftStore: new CreateNftStore(),
  currencyStore: new CurrencyStore(),
  networkStore: new NetworkStore(),
  nftStore: new NftStore(),
  tokenStore: new TokenStore(),
  userOnSaleNftStore: new UserOnSaleNftStore(),
  offerStore: new OfferStore(),
  profileStore: new ProfileStore(),
  walletStore: new WalletStore(),
};

export const StoreContext = createContext(store);

export const useStore = () => useContext<typeof store>(StoreContext);

injectStores(store);
