// Refactoring №2
import { CSSProperties } from 'react';
import { createTheme } from '@mui/material';

export const darkTheme = createTheme({
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#FFFFFF',
          '::placeholder': {
            color: '#717186',
            opacity: 1,
          },
        },
      },
    },
  },
  palette: {
    first: {
      main: '#211D2A', // black background
    },
    second: {
      main: 'rgba(42, 42, 58, 1)', //grey - use for active
      light: 'rgba(42, 42, 58, 0.6)', //grey - use for hover
      dark: 'rgba(42, 42, 58, 0.2)', //grey - use for normal
    },
    third: {
      main: '#232131', // grey for inputs
    },
    fourth: {
      main: '#717186', // light grey
      light: '#71718633',
    },
    fifth: {
      main: 'rgba(54, 130, 244, 1)', // blue - use for normal
      dark: 'rgba(54, 130, 244, 0.3)', // blue - use for hover
    },
    sixth: {
      main: '#C13FFF', // Pink
    },
    seventh: {
      main: '#791EEE', // Violet
    },
    eighth: {
      main: '#4FD4DC', // Turquoise
    },
    ninth: {
      main: '#FFFFFF', // White
    },
    tenth: {
      main: 'rgba(193, 55, 55, 1)', // red - use for errors text
      dark: 'rgba(193, 55, 55, 0.1)', // red - use for errors container background
    },
    eleventh: {
      main: '#40A044', // Green
    },
    twelfth: {
      main: '#DB9732', // Orange
    },
  },
  typography: {
    fontFamily: 'Work Sans',
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    h1: {
      fontWeight: 600, // Semibold
      fontSize: '24px',
      lineHeight: '28px',
    },
    h2: {
      fontWeight: 600, // Semibold
      fontSize: '18px',
      lineHeight: '21px',
    },
    t1: {
      fontWeight: 600, // Semibold
      fontSize: '16px',
      lineHeight: '19px',
    },
    t2: {
      fontWeight: 600, // Semibold
      fontSize: '14px',
      lineHeight: '16px',
    },
    t3: {
      fontWeight: 500, // Medium
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    first: Palette['primary'];
    second: Palette['primary'];
    third: Palette['primary'];
    fourth: Palette['primary'];
    fifth: Palette['primary'];
    sixth: Palette['primary'];
    seventh: Palette['primary'];
    eighth: Palette['primary'];
    ninth: Palette['primary'];
    tenth: Palette['primary'];
    eleventh: Palette['primary'];
    twelfth: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    first?: PaletteOptions['primary'];
    second?: PaletteOptions['primary'];
    third?: PaletteOptions['primary'];
    fourth?: PaletteOptions['primary'];
    fifth?: PaletteOptions['primary'];
    sixth?: PaletteOptions['primary'];
    seventh?: PaletteOptions['primary'];
    eighth?: PaletteOptions['primary'];
    ninth?: PaletteOptions['primary'];
    tenth?: PaletteOptions['primary'];
    eleventh?: PaletteOptions['primary'];
    twelfth?: PaletteOptions['primary'];
  }

  export interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
  }

  interface TypographyVariants {
    t1: CSSProperties;
    t2: CSSProperties;
    t3: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    t1?: CSSProperties;
    t2?: CSSProperties;
    t3?: CSSProperties;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    first: true;
    second: true;
    third: true;
    fourth: true;
    fifth: true;
    sixth: true;
    seventh: true;
    eighth: true;
    ninth: true;
    tenth: true;
    eleventh: true;
    twelfth: true;
  }
}

// Update the Chip's color prop options
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    first: true;
    second: true;
    third: true;
    fourth: true;
    fifth: true;
    sixth: true;
    seventh: true;
    eighth: true;
    ninth: true;
    tenth: true;
    eleventh: true;
    twelfth: true;
  }
}

// Update the Slider's color prop options
declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    first: true;
    second: true;
    third: true;
    fourth: true;
    fifth: true;
    sixth: true;
    seventh: true;
    eighth: true;
    ninth: true;
    tenth: true;
    eleventh: true;
    twelfth: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    t1: true;
    t2: true;
    t3: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    first: true;
    second: true;
    third: true;
    fourth: true;
    fifth: true;
    sixth: true;
    seventh: true;
    eighth: true;
    ninth: true;
    tenth: true;
    eleventh: true;
    twelfth: true;
  }
}
