import { getCurrenciesList } from 'api/currency';
import { ICurrenciesListRequest, ICurrencyData, IMetaResponse } from 'interfaces';
import { flow, makeAutoObservable } from 'mobx';

const initMeta = {
  itemsPerPage: 0,
  totalItems: 0,
  currentPage: 0,
  totalPages: 0,
};

class CurrencyStore {
  items: ICurrencyData[] = [];

  item: ICurrencyData | undefined = undefined;

  meta: IMetaResponse = initMeta;

  sorting: string[] = ['createdAt:DESC', 'createdAt:ASC'];

  constructor() {
    makeAutoObservable(this, { fetchItems: flow }, { autoBind: true });
  }

  reset() {
    console.log(111);
    this.items = [];
    this.item = undefined;
    this.meta = initMeta;
  }

  resetItem() {
    this.item = undefined;
  }

  selectItem(value: ICurrencyData) {
    this.item = value;
  }

  *fetchItems(params: ICurrenciesListRequest) {
    const { data, meta } = yield getCurrenciesList({ limit: 200, ...params });
    this.items = data;
    this.item = data[0];
    this.meta = meta;
  }

  get currentTokenSymbol(): string {
    return this.item?.name || '';
  }
}

export default CurrencyStore;
