// Refactoring №2
import API from 'api/API';
import {
  IListRequest,
  IListResponse,
  IMessageResponse,
  INewTransactionData,
  ITokenData,
  ITokenListRequest,
} from 'interfaces';

export const getTokenList = async (params?: ITokenListRequest): Promise<IListResponse<ITokenData>> => {
  const response = await API.get<IListResponse<ITokenData>>('/token', { params });
  return response.data;
};

export const getToken = async (id: string): Promise<ITokenData> => {
  const response = await API.get<ITokenData>(`/token/${id}`);
  return response.data;
};

export const getUserOnSaleTokensList = async (
  userId: string,
  params?: IListRequest,
): Promise<IListResponse<ITokenData>> => {
  const response = await API.get<IListResponse<ITokenData>>(`/token/on-sale/${userId}`, { params });
  return response.data;
};

export const makeTokenMint = async (data: FormData): Promise<INewTransactionData> => {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  const response = await API.post<FormData, INewTransactionData>('/token', data, config);
  return response.data;
};

export const addToFavorites = async (tokenUuid: string): Promise<IMessageResponse> => {
  const response = await API.post<never, IMessageResponse>(`/token/${tokenUuid}/favorite`);
  return response.data;
};

export const removeFromFavorites = async (tokenUuid: string): Promise<IMessageResponse> => {
  const response = await API.delete<IMessageResponse>(`/token/${tokenUuid}/favorite`);
  return response.data;
};
