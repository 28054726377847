import * as Yup from 'yup';

Yup.setLocale({
  string: {
    email: () => ({ key: 'email' }),
  },
});

export const SubscribeSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});
