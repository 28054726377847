// Refactoring №1
// Refactoring №2
import { makeAutoObservable } from 'mobx';

export default class WalletStore {
  connected = false;
  balance = '';
  currentTokenSymbol = '';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setConnected(value: boolean) {
    this.connected = value;
  }

  toggleConnected() {
    this.connected = !this.connected;
  }

  setBalance(value: string) {
    this.balance = value;
  }

  setCurrentTokenSymbol(value: string) {
    this.currentTokenSymbol = value;
  }
}
