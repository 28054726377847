// Refactoring №1
// Refactoring №2
import CryptoConvert from 'crypto-convert';

const convert = new CryptoConvert({
  cryptoInterval: 30000, //Crypto prices update interval in ms (default 5 seconds on Node.js & 15 seconds on Browsers)
  fiatInterval: 60 * 1e3 * 60, //Fiat prices update interval (default every 1 hour)
  calculateAverage: true, //Calculate the average crypto price from exchanges
  binance: false, //Use binance rates
  bitfinex: true, //Use bitfinex rates
  coinbase: true, //Use coinbase rates
  kraken: true, //Use kraken rates
});

export const getUsdValue = async (amount: string): Promise<string> => {
  await convert.ready(); //Wait for the initial cache to load

  const result = convert.ETH.USD(amount);

  return result ? result.toFixed(2).toString() : '0';
};

export const getNumberPlatformFee = (): number => {
  return 0.1;
};

export const getDisplayPlatformFee = (): string => {
  return '10%';
};

export const getPricePlusFee = (price: string, platformFee: number): string => {
  return parseFloat((+price + +price * platformFee).toFixed(4).toString()).toString();
};

export const getPriceMinusFee = (price: string, platformFee: number): string => {
  return parseFloat((+price - +price * platformFee).toFixed(4).toString()).toString();
};

export const getFeeValueFromPrice = (price: string, platformFee: number): string => {
  return parseFloat((+price * platformFee).toFixed(4).toString()).toString();
};

export const getRndInteger = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min)) + min;
};
