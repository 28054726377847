// Refactoring №2
import { format } from 'd3-format';
import { BigNumber, utils } from 'ethers';

export const formatter = format('.4~f');

export const formatUnits = (bg: BigNumber, unit = 'ether'): string => {
  const num = +utils.formatUnits(bg, unit);
  return formatter(num);
};
