import { IUser } from 'interfaces';
import { makeAutoObservable } from 'mobx';
import { BIO_LIMIT } from 'constants/common';

export default class AccountStore {
  bioLimit = BIO_LIMIT;

  username = '';
  gameId = '';
  email = '';
  bio = '';

  avatarFile: File | null = null;
  avatarDataUrl: string | null = null;

  bgFile: File | null = null;
  bgDataUrl: string | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  init(data: IUser) {
    this.username = data.username;
    this.gameId = data.gameId;
    this.email = data.email;
    this.bio = data.bio;
    this.avatarDataUrl = data.avatar;
    this.bgDataUrl = data.backgroundImg;
  }

  reset() {
    this.username = '';
    this.gameId = '';
    this.email = '';
  }

  setAvatarDataUrl(value: string) {
    this.avatarDataUrl = value;
  }

  setAvatarFile(image: File | null) {
    this.avatarFile = image;

    if (!image) {
      this.avatarDataUrl = null;
      return;
    }

    this.avatarDataUrl = URL.createObjectURL(image);
  }

  setBgFile(image: File | null) {
    this.bgFile = image;

    if (!image) {
      this.bgDataUrl = null;
      return;
    }

    this.bgDataUrl = URL.createObjectURL(image);
  }

  setUsername(value: string) {
    this.username = value;
  }

  setGameId(value: string) {
    this.gameId = value;
  }

  setEmail(value: string) {
    this.email = value;
  }

  setBio(value: string) {
    if (value.length <= this.bioLimit) {
      this.bio = value;
    }
  }

  get payload() {
    return {
      username: this.username,
      // gameId: this.gameId,
      email: this.email,
      bio: this.bio,
    };
  }

  get payloadString(): string {
    return JSON.stringify(this.payload);
  }

  get bioLength(): number {
    return this.bio ? this.bio.length : 0;
  }
}
