// Refactoring №2
import API from 'api/API';
import {
  ICreateOfferRequest,
  IListRequest,
  IListResponse,
  INewTransactionData,
  IOfferData,
  IOfferDetails,
  IOfferListRequest,
  IPurchaseRequest,
  IPurchaseResponse,
} from 'interfaces';

export const getOfferList = async (params: IOfferListRequest): Promise<IListResponse<IOfferData>> => {
  const response = await API.get<IListResponse<IOfferData>>('/offer', { params });
  return response.data;
};

export const getOffer = async (offerId: string): Promise<IOfferDetails> => {
  const response = await API.get<IOfferDetails>(`/offer/${offerId}`);
  return response.data;
};

export const getOfferListForToken = async (
  tokenId: string,
  params: IListRequest,
): Promise<IListResponse<IOfferData>> => {
  const response = await API.get<IListResponse<IOfferData>>(`/offer/${tokenId}/history`, { params });
  return response.data;
};

export const makePurchase = async (offerId: string, quantity: number): Promise<IPurchaseResponse> => {
  const response = await API.post<IPurchaseRequest, IPurchaseResponse>(`/offer/${offerId}/purchase`, { quantity });
  return response.data;
};

export const createOffer = async (tokenId: string, body: ICreateOfferRequest): Promise<INewTransactionData> => {
  const response = await API.post<ICreateOfferRequest, INewTransactionData>(`/offer/${tokenId}`, body);
  return response.data;
};

export const editOffer = async (id: string, body: ICreateOfferRequest): Promise<INewTransactionData> => {
  const response = await API.put<ICreateOfferRequest, INewTransactionData>(`/offer/${id}`, body);
  return response.data;
};

export const cancelOffer = async (id: string): Promise<INewTransactionData> => {
  const response = await API.delete<INewTransactionData>(`/offer/${id}`);
  return response.data;
};
