// Refactoring №1
import { getUserProfile } from 'api/auth';
import { IUser } from 'interfaces';
import { flow, makeAutoObservable } from 'mobx';
import { UserStateInitialization } from './AuthStore';

export default class ProfileStore {
  isLoading = false;
  item: IUser = { ...UserStateInitialization };

  constructor() {
    makeAutoObservable(this, { fetchProfile: flow }, { autoBind: true });
  }

  reset() {
    this.item = { ...UserStateInitialization };
    this.isLoading = false;
  }

  *fetchProfile(userId: string) {
    this.isLoading = true;

    const data: IUser = yield getUserProfile(userId);

    this.item = data;
    this.isLoading = false;
  }

  get avatar(): string {
    return this.item.avatar || '';
  }

  get username(): string {
    return this.item.username || '';
  }

  get metamaskWallet(): string {
    return this.item.metamaskWallet || '';
  }
}
