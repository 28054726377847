// Refactoring №2
export enum BuyNFTDialogType {
  PurchaseNFT = 'Purchase NFT',
  MakeOffer = 'Make an offer',
  SuccessfulPurchase = 'Purchase successful',
  FailedPurchase = 'Purchase failed',
  ConnectWallet = 'Want to participate?',
}

export enum SellNFTDialogType {
  SellNFT = 'Sell NFT',
  Auction = 'Auction sell',
  SellOfferEdit = 'Sell offer edit',
  AuctionOfferEdit = 'Auction offer edit',
  ConnectWallet = 'Want to participate?',
  OfferCreated = 'Congratulations!',
  FailedOfferCreation = 'Failed to create sale offer',
}

export enum AuctionTimeType {
  Start = 'start',
  End = 'end',
}

export enum TokenType {
  ERC1155 = 'ERC1155',
  ERC721 = 'ERC721',
}

export enum CreateNFTStatusDialogType {
  Successful = 'successful',
  Failed = 'failed',
}
