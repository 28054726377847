// Refactoring №2
import format from 'date-fns/format';

export const toShortDateFormat = (date: string): string => format(new Date(date), 'eee MMM d yyyy');

export const getCountdownTime = (timestamp: number) => {
  if (timestamp < 0) {
    return { days: '0', hours: '0', minutes: '0', seconds: '0' };
  }
  let seconds = (timestamp / 1000).toFixed(0);
  let minutes = '--';
  let hours = '--';
  let days = '--';

  if (+seconds > 60) {
    minutes = `${Math.floor(+seconds / 60) ? Math.floor(+seconds / 60) : 0}`;
    seconds = `${+seconds - +minutes * 60}`;
  }
  if (minutes) {
    hours = `${Math.floor(+minutes / 60) ? Math.floor(+minutes / 60) : 0}`;
    minutes = `${+minutes - +hours * 60}`;
  }
  if (hours) {
    days = `${Math.floor(+hours / 24) ? Math.floor(+hours / 60) : 0}`;
    hours = `${+hours - +days * 24}`;
  }
  return { days, hours, minutes, seconds };
};
