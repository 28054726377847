// Refactoring №1
// Refactoring №2
// Refactoring №3
import { t } from 'i18next';
import { flow, makeAutoObservable } from 'mobx';
import { getCollectionById, getCollectionList } from 'api/collection';
import { ICollectionData, ICollectionListRequest, IMetaResponse } from 'interfaces';
import { INIT_META } from 'constants/common';

class CollectionStore {
  isLoading = false;

  isInitialized = false;

  items: ICollectionData[] = [];

  isOpenCollections = false;

  selectedCollection: string = t('nfts.filters.allCollections');

  item: ICollectionData = {
    uuid: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    name: '',
    networkUuid: '',
    creatorUuid: '',
    imageHash: '',
  };

  meta: IMetaResponse = INIT_META;

  sorting: string[] = ['createdAt:DESC', 'createdAt:ASC'];

  constructor() {
    makeAutoObservable(this, { fetchItem: flow, fetchItems: flow }, { autoBind: true });
  }

  reset() {
    this.isOpenCollections = false;
    this.selectedCollection = t('nfts.filters.allCollections');
  }

  get collectionNames(): string[] {
    return this.items.map((c) => c.name);
  }

  getById(id: string): ICollectionData | undefined {
    const collection = this.items.find((c) => c.uuid === id);
    return collection;
  }

  getByName(name: string): ICollectionData | undefined {
    const collection = this.items.find((c) => c.name === name);
    return collection;
  }

  *fetchItems(params: ICollectionListRequest = {}) {
    const { data, meta } = yield getCollectionList({ limit: 200, ...params });
    this.items = data;
    this.meta = meta;
    this.isInitialized = true;
  }

  *fetchItem(id: string) {
    const data: ICollectionData = yield getCollectionById(id);
    this.item = data;
  }

  setIsOpenCollections(isOpen: boolean): void {
    this.isOpenCollections = isOpen;
  }

  setSelectedCollection(collection: string): void {
    this.selectedCollection = collection;
  }
}

export default CollectionStore;
