// Refactoring №1
// Refactoring №2
import { flow, makeAutoObservable } from 'mobx';
import { LS_KEY_ACCESS_TOKEN, LS_KEY_REFRESH_TOKEN } from 'constants/localStorage';
import { IUser } from 'interfaces';
import { UserRole } from 'types';
import { getFromLS, removeFromLS, setToLS } from 'utils';
import { getMe } from 'api/auth';

interface ISetAuth {
  accessToken: string;
  refreshToken: string;
}

export const UserStateInitialization: IUser = {
  uuid: '',
  role: UserRole.User,
  username: '',
  gameId: '',
  email: '',
  isEmailConfirmed: false,
  isTwoFactorAuthenticationEnabled: false,
  googleId: null,
  phoneNumber: null,
  isPhoneNumberConfirmed: false,
  metamaskWallet: null,
  name: null,
  avatar: null,
  bio: '',
  backgroundImg: null,
};

export default class AuthStore {
  isLoading = false;
  isAuthorized = !!getFromLS(LS_KEY_ACCESS_TOKEN);
  user: IUser = UserStateInitialization;
  loginRequired = false;

  constructor() {
    makeAutoObservable(this, { fetchMe: flow }, { autoBind: true });
  }

  setAuth(payload: ISetAuth) {
    const { accessToken, refreshToken } = payload;

    setToLS(LS_KEY_ACCESS_TOKEN, accessToken);
    setToLS(LS_KEY_REFRESH_TOKEN, refreshToken);

    this.isLoading = !!accessToken;
    this.isAuthorized = !!accessToken;
  }

  signOut() {
    removeFromLS(LS_KEY_ACCESS_TOKEN);
    removeFromLS(LS_KEY_REFRESH_TOKEN);

    this.isAuthorized = false;
    this.isLoading = false;
  }

  setUser(payload: IUser) {
    this.user = payload;
    this.isAuthorized = true;
    this.isLoading = false;
  }

  setLoginRequired(value: boolean) {
    this.loginRequired = value;
  }

  get isAdmin(): boolean {
    return this.user.role === UserRole.Admin;
  }

  get walletAddress(): string {
    return this.user.metamaskWallet || '';
  }

  get hasAccount(): boolean {
    return this.user.uuid !== '';
  }

  get accountPayload() {
    return {
      username: this.user.username,
      gameId: this.user.gameId,
      email: this.user.email,
      bio: this.user.bio,
    };
  }

  get accountPayloadString(): string {
    return JSON.stringify(this.accountPayload);
  }

  *fetchMe() {
    try {
      this.isLoading = true;

      const user: IUser = yield getMe();

      this.setUser(user);
    } catch (error) {
      this.signOut();
    }
  }

  isWalletOwner(addr: string): boolean {
    if (!this.user) {
      return false;
    }

    return this.user.metamaskWallet?.toLowerCase() === addr.toLowerCase();
  }
}
