// Refactoring №1
// Refactoring №2
import { flow, makeAutoObservable } from 'mobx';
import { getToken, getTokenList } from 'api/token';
import { IMetaResponse, ITokenData, ITokenListRequest } from 'interfaces';
import { INIT_META } from 'constants/common';

class TokenStore {
  isLoading = false;

  items: ITokenData[] = [];

  item: ITokenData | undefined;

  meta: IMetaResponse = INIT_META;

  sorting: string[] = ['createdAt:DESC', 'createdAt:ASC'];

  constructor() {
    makeAutoObservable(this, { fetchTokens: flow }, { autoBind: true });
  }

  reset() {
    this.items = [];
    this.meta = INIT_META;
    this.isLoading = false;
  }

  resetToken() {
    this.item = undefined;
  }

  *fetchToken(tokenId: string) {
    this.isLoading = true;

    const item: ITokenData = yield getToken(tokenId);

    this.item = item;
    this.isLoading = false;
  }

  *fetchTokens(params: ITokenListRequest = {}) {
    this.isLoading = true;

    const { data, meta } = yield getTokenList({ limit: 12, ...params });

    if (params.page && params.page > this.meta.currentPage) {
      this.items = [...this.items, ...data];
    } else {
      this.items = data;
    }

    this.meta = meta;
    this.isLoading = false;
  }
}

export default TokenStore;
