import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Pages } from 'routes';
import { useStore } from 'store';

export const App = observer(() => {
  const { authStore } = useStore();

  useEffect(() => {
    if (authStore.isAuthorized) {
      authStore.fetchMe();
    }
  }, [authStore.isAuthorized]);

  return <Pages />;
});
