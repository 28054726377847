// Refactoring №2
export const REQUEST_TIMEOUT = 10000;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

export const INIT_META = {
  itemsPerPage: 0,
  totalItems: 0,
  currentPage: 0,
  totalPages: 0,
};

export const NAME_LIMIT = 32;

export const DESCRIPTION_LIMIT = 540;

export const BIO_LIMIT = 256;

export const MAX_PROPERTIES_COUNT = 20;
