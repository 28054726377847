// Refactoring №1
// Refactoring №2
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'i18n';
import { providers } from 'ethers';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Web3ReactProvider } from '@web3-react/core';
import { store, StoreContext } from 'store';
import { App } from './App';
import MainLoader from 'components/MainLoader';
import { GOOGLE_CLIENT_ID } from 'constants/common';
import { darkTheme } from 'themes/darkTheme';
import { NotificationsProvider, ScreenSizeProvider } from 'utils';
import './index.scss';

const getLibrary = (provider: providers.ExternalProvider | providers.JsonRpcFetchFunc): providers.Web3Provider => {
  const library = new providers.Web3Provider(provider);
  library.pollingInterval = 15000; // frequency provider is polling
  return library;
};

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find the root element');
}
const root = ReactDOM.createRoot(rootElement);
root.render(
  <StrictMode>
    <StoreContext.Provider value={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={darkTheme}>
          <ScreenSizeProvider>
            <NotificationsProvider>
              <Web3ReactProvider getLibrary={getLibrary}>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                  <Suspense fallback={<MainLoader className='suspense' isLoading />}>
                    <App />
                  </Suspense>
                </GoogleOAuthProvider>
              </Web3ReactProvider>
            </NotificationsProvider>
          </ScreenSizeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </StoreContext.Provider>
  </StrictMode>,
);
