// Refactoring №1
// Refactoring №2
// Refactoring №3
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isLoading?: boolean;
  children?: ReactNode;
  className?: string;
}

const MainLoader: FC<IProps> = ({ isLoading, children, className = '' }) => {
  const { t } = useTranslation();

  if (isLoading) {
    // TODO: add styles & spinner
    return <div className={className}>{t('loading')}</div>;
  }
  return <>{children}</>;
};

export default MainLoader;
