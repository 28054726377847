// Refactoring №3
import { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Routes as RoutesList } from 'constants/routes';
import { ProtectedRoute } from './ProtectedRoute';

const SettingsPage = lazy(() => import('pages/SettingsPage'));
const HomePage = lazy(() => import('pages/HomePageView'));
const MarketplacePage = lazy(() => import('pages/MarketplacePage'));
const BreedingPage = lazy(() => import('pages/Breeding'));
const NFTPage = lazy(() => import('pages/NFTPage'));
const ResetPasswordPage = lazy(() => import('pages/ResetPassword'));
const ProfilePage = lazy(() => import('pages/Profile'));
const EmailConfirmed = lazy(() => import('pages/EmailConfirmed'));
const PublicProfilePage = lazy(() => import('pages/PublicProfilePage'));
const CollectionPage = lazy(() => import('pages/CollectionPage'));
const OfferPage = lazy(() => import('pages/OfferPage'));
const CreateNftPage = lazy(() => import('pages/CreateNftPage'));
const CreateCollectionPage = lazy(() => import('pages/CreateCollectionPage'));

export const MainRoutes = [
  { path: RoutesList.home, component: <HomePage /> },
  { path: RoutesList.allNFTs, component: <MarketplacePage /> },
  { path: RoutesList.breeding, component: <BreedingPage /> },
  { path: RoutesList.nft, component: <NFTPage /> },
  { path: RoutesList.resetPassword, component: <ResetPasswordPage /> },
  { path: RoutesList.emailConfirmed, component: <EmailConfirmed /> },
  { path: RoutesList.publicProfile, component: <PublicProfilePage /> },
  { path: RoutesList.collection, component: <CollectionPage /> },
  { path: RoutesList.offer, component: <OfferPage /> },
  {
    path: RoutesList.profile,
    component: (
      <ProtectedRoute>
        <ProfilePage />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesList.settingsPage,
    component: (
      <ProtectedRoute>
        <SettingsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesList.createNft,
    component: (
      <ProtectedRoute>
        <CreateNftPage />
      </ProtectedRoute>
    ),
  },
  {
    path: RoutesList.createCollection,
    component: (
      <ProtectedRoute>
        <CreateCollectionPage />
      </ProtectedRoute>
    ),
  },
];

export const Pages = () => (
  <BrowserRouter>
    <Routes>
      {MainRoutes.map(({ path, component }) => (
        <Route
          key={`route-${path}`}
          path={path}
          element={path === RoutesList.home ? <Navigate to='/nfts' replace /> : component}
        />
      ))}
      <Route path='*' element={<div>404</div>} />
    </Routes>
  </BrowserRouter>
);
