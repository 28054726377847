// Refactoring №1
// Refactoring №2
import { flow, makeAutoObservable, runInAction } from 'mobx';
import { getOffer, getOfferList, getOfferListForToken } from 'api/offer';
import { IListRequest, IMetaResponse, IOfferData, IOfferDetails, IOfferListRequest } from 'interfaces';
import { INIT_META } from 'constants/common';

class OfferStore {
  isLoading = false;

  items: IOfferData[] = [];

  item: IOfferDetails | undefined;

  meta: IMetaResponse = INIT_META;

  sorting: string[] = ['createdAt:DESC', 'createdAt:ASC'];

  constructor() {
    makeAutoObservable(this, { fetchOffers: flow, fetchTokenOffers: flow }, { autoBind: true });
  }

  reset() {
    this.items = [];
    this.meta = INIT_META;
    this.isLoading = false;
  }

  resetOffer() {
    this.item = undefined;
  }

  async fetchOffer(id: string) {
    this.isLoading = true;

    const item: IOfferDetails = await getOffer(id);

    runInAction(() => {
      this.item = item;
      this.isLoading = false;
    });
  }

  *fetchOffers(params: IOfferListRequest = {}) {
    this.isLoading = true;

    const { data, meta } = yield getOfferList({ limit: 25, ...params });

    if (params.page && params.page > this.meta.currentPage) {
      this.items = [...this.items, ...data];
    } else {
      this.items = data;
    }

    this.meta = meta;
    this.isLoading = false;
  }

  *fetchTokenOffers(tokenId: string, params: IListRequest = {}) {
    this.isLoading = true;

    const { data, meta } = yield getOfferListForToken(tokenId, { limit: 25, ...params });

    if (params.page && params.page > this.meta.currentPage) {
      this.items = [...this.items, ...data];
    } else {
      this.items = data;
    }

    this.meta = meta;
    this.isLoading = false;
  }

  get hasItems(): boolean {
    return this.items.length > 0;
  }

  get totalItems(): number {
    return this.meta.totalItems;
  }

  get totalPages(): number {
    return this.meta.totalPages;
  }

  get currentPage(): number {
    return this.meta.currentPage;
  }

  get nextPage(): number {
    return this.currentPage + 1;
  }
}

export default OfferStore;
