// Refactoring №1
// Refactoring №2
import { flow, makeAutoObservable } from 'mobx';
import { getUserOnSaleTokensList } from 'api/token';
import { IListRequest, IMetaResponse, ITokenData } from 'interfaces';
import { INIT_META } from 'constants/common';

class UserOnSaleNftStore {
  isLoading = false;

  nfts: ITokenData[] = [];

  meta: IMetaResponse = INIT_META;

  sorting: string[] = ['createdAt:DESC', 'createdAt:ASC'];

  constructor() {
    makeAutoObservable(this, { fetchOnSaleNfts: flow }, { autoBind: true });
  }

  reset() {
    this.nfts = [];
    this.meta = INIT_META;
    this.isLoading = false;
  }

  *fetchOnSaleNfts(userId: string, params: IListRequest = {}) {
    this.isLoading = true;

    const { data, meta } = yield getUserOnSaleTokensList(userId, { limit: 12, ...params });

    if (params.page && params.page > this.meta.currentPage) {
      this.nfts = [...this.nfts, ...data];
    } else {
      this.nfts = data;
    }

    this.meta = meta;
    this.isLoading = false;
  }
}

export default UserOnSaleNftStore;
