// Refactoring №2
import API from 'api/API';
import {
  IConfirmRequest,
  IConnectMetaMaskRequest,
  IForgotPasswordRequest,
  ILoginByGoogleResponse,
  ILoginRequest,
  ILoginResponse,
  IMessageResponse,
  INewTransactionData,
  IRegisterByEmailRequest,
  IRequestAllowanceMarketplace,
  IRequestAllowanceOffer,
  IResetPasswordForceRequest,
  IResetPasswordRequest,
  IUser,
} from 'interfaces';

export const confirmEmail = async (body: IConfirmRequest): Promise<IMessageResponse> => {
  const response = await API.post<IConfirmRequest, IMessageResponse>('/auth/confirm/email', body);
  return response.data;
};

export const requestPassword = async (body: IForgotPasswordRequest): Promise<IMessageResponse> => {
  const response = await API.post<IForgotPasswordRequest, IMessageResponse>('/auth/reset-password/request', body);
  return response.data;
};

export const resetPassword = async (body: IResetPasswordRequest): Promise<IMessageResponse> => {
  const response = await API.post<IResetPasswordRequest, IMessageResponse>('/auth/reset-password', body);
  return response.data;
};

export const resetPasswordForce = async (body: IResetPasswordForceRequest): Promise<IMessageResponse> => {
  const response = await API.post<IResetPasswordForceRequest, IMessageResponse>('/auth/reset-password/force', body);
  return response.data;
};

export const registerByEmail = async (body: IRegisterByEmailRequest): Promise<IMessageResponse> => {
  const response = await API.post<IRegisterByEmailRequest, IMessageResponse>('/auth/register/email', body);
  return response.data;
};

export const logIn = async (body: ILoginRequest): Promise<ILoginResponse> => {
  const response = await API.post<ILoginRequest, ILoginResponse>('/auth/login', body);
  return response.data;
};

export const logInByGoogle = async (body: ILoginByGoogleResponse): Promise<ILoginResponse> => {
  const response = await API.post<ILoginByGoogleResponse, ILoginResponse>('/auth/login/google', body);
  return response.data;
};

export const logInByWallet = async (body: IConnectMetaMaskRequest): Promise<ILoginResponse> => {
  const response = await API.post<IConnectMetaMaskRequest, ILoginResponse>('/auth/login/wallet', body);
  return response.data;
};

export const getMe = async (): Promise<IUser> => {
  const response = await API.get<IUser>('/auth/me');
  return response.data;
};

export const connectMetamask = async (body: IConnectMetaMaskRequest): Promise<void> => {
  await API.post('/auth/connect-metamask', body);
};

export const getUserProfile = async (id: string): Promise<IUser> => {
  const response = await API.get<IUser>(`/auth/profile/${id}`);
  return response.data;
};

export const checkAllowanceMarketplace = async (tokenUuid: string): Promise<boolean> => {
  const response = await API.get<boolean>('/auth/wallet/allowance/nft/marketplace', { params: { tokenUuid } });
  return response.data;
};

export const requestAllowanceMarketplace = async (tokenUuid: string): Promise<INewTransactionData> => {
  const response = await API.post<IRequestAllowanceMarketplace, INewTransactionData>(
    '/auth/wallet/allowance/nft/marketplace',
    { tokenUuid },
  );
  return response.data;
};

export const checkAllowanceOffer = async (offerUuid: string): Promise<boolean> => {
  const response = await API.get<boolean>('/auth/wallet/allowance/erc20', { params: { offerUuid } });
  return response.data;
};

export const requestAllowanceOffer = async (offerUuid: string): Promise<INewTransactionData> => {
  const response = await API.post<IRequestAllowanceOffer, INewTransactionData>('/auth/wallet/allowance/erc20', {
    offerUuid,
  });
  return response.data;
};

export const getBalance = async (currencyUuid: string): Promise<number> => {
  const response = await API.get<number>('/auth/wallet/balance', { params: { currencyUuid } });
  return response.data;
};
