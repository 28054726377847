export enum MyPages {
  MyGallery = 'myGallery',
  OnSale = 'onSale',
  Purchased = 'purchased',
  Created = 'created',
  Favorites = 'favorites',
}

export enum PublicProfilePages {
  Gallery = 'gallery',
  OnSale = 'onSale',
}
