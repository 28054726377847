// Refactoring №1
// Refactoring №2
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import transakSDK from '@transak/transak-sdk';

const transak = new transakSDK({
  apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
  environment: process.env.REACT_APP_TRANSAK_ENV,
  hostURL: window.location.origin,
  themeColor: '#211D2A',
  redirectURL: '',
  widgetHeight: '550px',
  defaultCryptoCurrency: 'ETH',
});

transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, () => {
  transak.close();
});

export default transak;
