// Refactoring №1
// Refactoring №2
export enum Routes {
  home = '/',
  allNFTs = '/nfts',
  // not for mvp
  // store = '/nfts',
  // listings = '/listings',
  breeding = '/breeding',
  nft = '/nfts/:id',
  profile = '/profile',
  resetPassword = '/reset-password/:token',
  emailConfirmed = '/confirmation',
  createNft = '/create-nft',
  createCollection = '/create-collection',
  adminProfile = 'admin-profile',
  settingsPage = '/settings',
  publicProfile = '/profile/:id',
  collection = '/collections/:id',
  offer = '/offers/:id',
}
