import {
  Context,
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useState,
} from 'react';
import { NotificationTypes } from 'types';

interface NotificationsContextValues {
  isNotification: boolean;
  type: NotificationTypes;
  message: string;
  onClose: () => void;
  setIsNotification: Dispatch<SetStateAction<boolean>>;
  setType: Dispatch<SetStateAction<NotificationTypes>>;
  setMessage: Dispatch<SetStateAction<string>>;
  showNotification: (type: NotificationTypes, nessage: string) => void;
}

interface NotificationsProviderProps {
  children?: ReactNode;
  // any props that come into the component
}

const NotificationsContext: Context<null | NotificationsContextValues> =
  createContext<null | NotificationsContextValues>(null);

export const NotificationsProvider: FC<NotificationsProviderProps> = ({ children }) => {
  const [type, setType] = useState<NotificationTypes>(NotificationTypes.success);
  const [message, setMessage] = useState<string>('');
  const [isNotification, setIsNotification] = useState<boolean>(false);

  const onClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsNotification(false);
    setTimeout(() => setMessage(''), 100);
  };

  const showNotification = (type: NotificationTypes, message: string) => {
    setIsNotification(true);
    setType(type);
    setMessage(message);
  };

  return (
    <NotificationsContext.Provider
      value={{ isNotification, type, message, onClose, setIsNotification, setType, setMessage, showNotification }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error('Missing referrer context');
  }

  return {
    isNotification: context.isNotification,
    type: context.type,
    message: context.message,
    onClose: context.onClose,
    setIsNotification: context.setIsNotification,
    setType: context.setType,
    setMessage: context.setMessage,
    showNotification: context.showNotification,
  };
};
